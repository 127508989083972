@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

.container {
    background-color: #343333;
    padding: 3% 3.5%;
    border-radius: 20px;
    max-width: 500px;
    max-height: 350px;
    margin: 5vw auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.headerText {
    color: #1CB82C;
    margin: 0;

    font-size: 3rem;
}

.input {
    margin-top: 2%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.miniInput {
    margin-top: 3%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputText {
    color: #1CB82C;
    margin: 0;

    font-size: 1.7rem;
}

.inputBar {
    width: 100%;
    height: 24px;
    margin-top: 1.3%;
    border-style: none;

    background-color: #5E695F;
}

.instructions {
    margin-top: 5%;
}

.instructionsText {
    margin: 0;
    text-align: left;
    font-family: Roboto;
    font-weight: 900;
    font-size: 1rem;
    line-height: 1.2;
    color: white;
}

/* wrapper for second column search bar + soc follow */
.socWrapper {
  /* make height and padding same as calendar col to align items */
  box-sizing: border-box;
  max-height: calc(100vh - 110px); 
  height: 100%;
  padding: 30px 0px 9px 0px;
}

/* REMOVE SCROLLBARS IN SOC SEARCH COLUMN */
.socWrapper *::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.socWrapper * {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}


.followBox {
  background-color: white;
  color: #64DF79;

  border-radius: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 20px;
  overflow-y: scroll;

  height: calc(100% - 40.56px - 1.5em);
  position: relative;
}

.followSoc {
  background-color: #E0E0E0;
  transition: background-color 400ms linear;
  transition: transform .2s ease-in-out;
  color: #988C8C;

  width: 80%;
  margin-top: 5px;
  border-radius: 10px;
  padding: 0 5px;

  font-size: 1.3em;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.followSoc p {
  margin: 0;
  font-family: Roboto;
  font-size: 1em;
  font-weight: 500;

  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  text-align: center;
}

.followSoc:hover {
  transform: scale(1.1);
}

.followSocActive {
  background-color: #B9EEFF;
  transition: background-color 400ms linear;
  transition: transform .2s ease-in-out;
}
.fc-day-today {
  background-color: #ffffff;
}

.fc td {
  background-color: #ffffff;

}

.fc th {
  background-color: #ffffff;

}

.fc-widget-header{
  background-color:rgb(0, 255, 85);
}

.fc-button-primary {
  background-color: rgb(100,223,121) !important; 
}

/* .fc td[class*="other-month"] {
  color: #ffffff;
  background-color: bluevioslet;
} */

.fc-toolbar h2 {
  margin: 0;
  color: #FFF;
}

.fc { /* the calendar root */

  max-width: 1000px;
  max-height: calc(100vh - 110px);
  margin: 0 auto;
  padding: 30px 0px 9px 9px;
}
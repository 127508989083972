.navBar {
    color: rgb(0, 0, 0);
    background-color: rgb(100,223,121); 
    display: flex;
    justify-content: space-between; 
    padding: 0px 25px 0px 25px;
    align-items: center;
    font-size: 21px;
}
  
.navBar ul{
    padding: 0;
    margin-right: 10px;
    list-style: none;
    display: flex;
    gap: 27px; 
    cursor: pointer;
} 

.navBar li:hover{
    color: rgb(161, 245, 183);
}

.logo {   
    height: 50px;    
    margin: 10px; 
}


 


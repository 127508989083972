.searchBarContainer {
  margin-bottom: 1.5em;
  max-height: 40.56px;
}

.searchBar {
  background-color: #D9D9D9;
  border-style: none;
  
  /* height, font-size and margin-bottom (above) match that of calendar header */
  width: calc(100% - 2 * 20px); /* width 100% including padding */
  height: 40.56px;  

  border-radius: 15px;
  padding: 0 20px;
  margin-bottom: 5px;

  font-weight: 700;
  font-size: 1em;
  font-family: Arial, FontAwesome;
}

.searchBar::placeholder {
  letter-spacing: 1.5px;
}

.searchMatches {
  /* number of matches * 3 */
  max-height: calc(25px * 3);
  overflow-y: scroll;
}

.searchMatch {
  background-color: #64DF79;
  transition: background-color 400ms linear;
  color: white;
  text-align: left;

  box-sizing: border-box;
  border: 1px solid rgb(36,32,32);
  border-radius: 10px;
  height: 25px;
  padding: 0 20px;

  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1.5px;

  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  position: relative;
  z-index: 100;
}

.searchMatchActive {
  background-color: #B9EEFF;
  transition: background-color 400ms linear;
}

input:focus {
  outline: none;
}
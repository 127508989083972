
.timeline_dot_header {
    background-color: rgb(38, 128, 98);
    color: black;
} 

.timeline_dot {
    border-color: rgb(38, 128, 98);
} 

.timeline_date {
    color: rgb(58, 241, 88);
} 
 

.timeline_content {
    color: aliceblue;
}

.change-log-block {
    background-color: rgb(34, 33, 33);
}

.change-log-block h1 {
    color: rgb(115, 221, 113);
    text-align: center;
}


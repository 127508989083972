$noOfTags: 10;
$tagGap: 2%;

.tagContainer {
  /* make height and padding same as calendar col to align items */
  box-sizing: border-box;
  height: calc(100vh - 110px); 

  padding: 30px 0px 9px 0px;
  border-radius: 20px;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  gap: $tagGap;
}

.tag {
  background-color: rgb(100,223,121);
  transition: background-color 400ms linear;
  transition: transform .2s ease-in-out;

  width: 100%;
  height: calc((100% - $tagGap * ($noOfTags - 1)) / ($noOfTags));
  padding: 0 5px;
  border-radius: 10px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
}

.tag:hover {
  transform: scale(1.01);
}

.tagActive {
  background-color: #B9EEFF;
  transition: background-color 400ms linear;
  transition: transform .2s ease-in-out;
}

